<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" label-width="120px" @keyup.enter.native="getQrcdsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料编号：">
                <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料名称：">
                <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="库位：">
                <el-select size="small" filterable v-model="searchForm.qrcd_bin" placeholder="请选择库位" clearable>
                  <el-option v-for="item in binList" :key="item.id" :label="item.param1" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="潘通色号：">
                <el-input size="small" v-model="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料类型：">
                <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable>
                  <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="所属部门：">
                <selectSectionType ref="selectSectionType" @selectStatusRow="getStatusVal2" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="颜色大类：">
                <el-select size="small" v-model="searchForm.colr_class" placeholder="请选择颜色大类" clearable multiple filterable>
                  <el-option v-for="item in optnColorList" :key="item.param1" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="16">
              <el-form-item label="时间范围：">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getQrcdsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button type="primary" size="small" class="vd_export" @click="print()"><i class="el-icon-s-promotion"></i> 打印</el-button>
        <el-button type="success" size="small" class="vd_export" @click="recycleBin()"><i class="el-icon-refresh"></i> 回收站</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            border
            v-loading="loadFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="物料编号" prop="mtrl_no" />
            <el-table-column label="物料图片" align="center">
              <template slot-scope="scope">
                <el-popover v-if="scope.row.imge_url" placement="right" width="400" trigger="hover">
                  <div>
                    <el-image style="width: 100%" class="vd_popimg" :src="formatPicO(scope, 'l')" fit="fill"> </el-image>
                  </div>
                  <el-image slot="reference" class="vd_elimg" :src="formatPicO(scope, 's')" fit="fill"> </el-image>
                </el-popover>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="二维码图片" align="center">
              <template slot-scope="scope">
                <el-image class="vd_elimg" v-if="scope.row.qrcd_url" :src="formatPic(scope, 'l')" fit="fill"> </el-image>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="二维码编号">
              <template slot-scope="scope">
                <span @click="copySty(scope.row.qrcd_id)" class="vg_cursor">mt{{ scope.row.qrcd_id }}</span>
              </template>
            </el-table-column>
            <el-table-column label="物料名称" prop="mtrl_name" />
            <el-table-column label="物料类型" prop="mtrl_type" :formatter="formatLeavType" />
            <el-table-column label="潘通色号" prop="mtrl_color" width="180">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div :class="{ vd_div: scope.row.colr_html }" :style="{ 'background-color': scope.row.colr_html }"></div>
                    <span>{{ scope.row.mtrl_color }}</span>
                  </div>
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column prop="colr_class" label="颜色大类" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.colr_class">
                  {{ scope.row.colr_class }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="门幅" prop="mtrl_width" :formatter="formatLeavwidth" />
            <el-table-column label="数量" prop="qrcd_num">
              <template slot-scope="scope">
                <div v-if="scope.row.qrcd_num">
                  {{ scope.row.qrcd_num | formatQrcdNum }}
                </div>
                <div v-else class="vg_9f9a9a">0.0000</div>
              </template>
            </el-table-column>
            <el-table-column label="物料单位" prop="mtrl_unit">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_unit">
                  {{ scope.row.mtrl_unit }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="库位" prop="qrcd_bin_no">
              <template slot-scope="scope">
                <span v-if="scope.row.qrcd_bin_no">
                  {{ scope.row.qrcd_bin_no }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="毛高">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_thick || scope.row.mtrl_thick === 0">{{ scope.row.mtrl_thick | formatQrcdMtrlThick }}毫米</div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column label="规格">
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_spec">
                  {{ scope.row.mtrl_spec }}
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column prop="belo_dept_name" label="所属部门"></el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />
          </el-table>
        </el-col>
      </el-row>

      <el-dialog title="" :visible.sync="dialogVisible" width="30%">
        <qrcdPrint :qrcdPrint="qrcdPrint" @printCancel="printCancel"></qrcdPrint>
      </el-dialog>

      <el-dialog title="" :visible.sync="recycleBinFlag" width="70%">
        <qrcdListHui ref="qrcdListHui" @confirmIn="confirmIn"></qrcdListHui>
      </el-dialog>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { qrcdAPI } from '@api/modules/qrcd';
import { optnAPI } from '@api/modules/optn';
import helper from '@assets/js/helper.js';
import pubPagination from '@/components/common/pubPagination';
import qrcdPrint from './TabChild/Componet/QrcdPrint.vue';
import selectSectionType from '@/views/component/selectSectionType';
import qrcdListHui from './TabChild/Componet/QrcdListHui.vue';
import publicTips from '@/views/component/publicTips';
// import {getLodop} from '../../../plugins/LodopFuncs'

export default {
  name: 'QrcdList',
  components: {
    pubPagination,
    qrcdPrint,
    qrcdListHui,
    publicTips,
    selectSectionType
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mtrl_no: null,
        mtrl_name: null,
        qrcd_bin: null,
        mtrl_color: null,
        mtrl_type: null,
        colr_class: [],
        timeValue: {
          startTime: null,
          endTime: null
        },
        belo_dept_id: null // 打样部门
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      binList: [],
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      dialogVisible: false,
      qrcdPrint: [],
      recycleBinFlag: false,
      selectRefuDisabled: false,
      optnColorList: [],
      isDyj: true,
      beloDeptId: null
    };
  },
  created() {
    this.getDepartment();
    this.initData();
  },
  filters: {
    formatQrcdNum(row) {
      return helper.haveFour(row);
    },
    formatQrcdMtrlThick(row) {
      return helper.reservedDigits(row);
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === '/qrcd_add') {
        this.getQrcdsList();
      }
    }
  },
  methods: {
    initData() {
      this.loadFlag = true;
      this.getQrcdsList();
      this.getBinList();
      // this.getDepartment()
      this.getOptnColor();
    },
    // 获取颜色大类option
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 })
        .then(res => {
          if (res.data.code === 0) {
            this.binList = res.data.data;
          } else {
            const mg = res.data.msg;
            const tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          const mg = res.data.msg;
          const tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取二维码信息
    getQrcdsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      let colr_class = null;
      if (this.searchForm.colr_class.length > 0) {
        colr_class = this.searchForm.colr_class.join(',');
      }
      get(qrcdAPI.getQrcds, {
        mtrl_no: this.searchForm.mtrl_no,
        mtrl_name: this.searchForm.mtrl_name,
        qrcd_bin: this.searchForm.qrcd_bin,
        mtrl_color: this.searchForm.mtrl_color,
        mtrl_type: this.searchForm.mtrl_type,
        belo_dept_id: this.searchForm.belo_dept_id,
        colr_class,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            const mg = res.data.msg;
            const tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          const mg = res.data.msg;
          const tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 判断是否 是DYJ员工
    getDepartment() {
      const userInfo = this.$cookies.get('userInfo');
      if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
        this.searchForm.belo_dept_id = `${userInfo.dept_id}`;
        this.beloDeptId = `${userInfo.dept_id}`;
        this.isDyj = true;
      } else {
        this.searchForm.belo_dept_id = null;
        this.isDyj = false;
      }
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mtrl_no: null,
        mtrl_name: null,
        qrcd_bin: null,
        mtrl_color: null,
        colr_class: [],
        timeValue: {
          startTime: null,
          endTime: null
        }
      };
      if (this.isDyj) {
        this.searchForm.belo_dept_id = this.beloDeptId;
      } else {
        this.searchForm.belo_dept_id = null;
        this.$refs.selectSectionType.value2 = '全部';
      }
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 关闭打印页面
    printCancel() {
      this.dialogVisible = false;
    },
    // 回收站打开页面
    recycleBin() {
      this.recycleBinFlag = true;
      setTimeout(() => {
        this.$refs.qrcdListHui.initData();
      }, 100);
    },
    // 关闭回收页面
    confirmIn() {
      this.recycleBinFlag = false;
      this.getQrcdsList();
    },
    // 打印
    print() {
      if (this.multiSelection.length > 0) {
        if (this.multiSelection.length <= 10) {
          this.qrcdPrint = this.multiSelection;
          this.dialogVisible = true;
        } else {
          this.$message({ message: '最多只能打印10条', type: 'warning' });
        }
      } else {
        this.$message({ message: '至少选择一条数据', type: 'warning' });
      }

      // const LODOP = getLodop()
      // this.qrcdPrint = []
      // if(this.multiSelection.length>0){
      //   // this.dialogVisible = true;
      //   // this.qrcdPrint = this.multiSelection
      //   LODOP.PRINT_INIT('')
      //   // LODOP.SET_PRINT_PAGESIZE(1,'50px',80*this.multiSelection.length+0.3*(this.multiSelection.length)+'px',"")
      //   LODOP.SET_PRINT_PAGESIZE(1,'50mm','80mm','')
      //   LODOP.SET_PRINT_STYLE('Alignment','2')
      //   for(let i=0;i<this.multiSelection.length;i++){
      //     let temp = this.multiSelection[i].qrcd_url
      //     LODOP.SET_PRINT_STYLE("FontSize",10)
      //     //LODOP.ADD_PRINT_HTM(0,0,300,100,"<img width='140px' height='140px' src='http://192.168.1.38:8885/file"+temp+".jpg'/>")
      //     LODOP.ADD_PRINT_IMAGE(80*(i)+5+'mm','5mm','40mm','40mm',"<img width='140px'height='140px' src='http://47.97.37.94/file"+temp+".jpg'/>")
      //     LODOP.ADD_PRINT_TEXT(80*(i)+45+'mm','0mm','50mm','10mm',"物料编号："+this.multiSelection[i].mtrl_no)
      //     LODOP.ADD_PRINT_TEXT(80*(i)+53+'mm','0mm','50mm','10mm',"物料名称："+this.multiSelection[i].mtrl_name)
      //     LODOP.ADD_PRINT_TEXT(80*(i)+61+'mm','0mm','50mm','10mm',"潘通色号："+this.multiSelection[i].mtrl_color)
      //     LODOP.ADD_PRINT_TEXT(80*(i)+69+'mm','0mm','50mm','10mm',"库位名称："+this.multiSelection[i].qrcd_bin_no)
      //   }
      //   // LODOP.SET_PRINT_COPIES(this.multiSelection.length)
      //   LODOP.PREVIEW()
      // }else{
      //   this.$message({type:'warning',message:'请至少选择一条数据！'})
      // }
    },
    // 查询方法
    getQrcdsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.initData();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/qrcd_add?perm_id=${permId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getQrcdsList();
    },
    // 图片类型
    formatPic(scope, type) {
      return this.helper.picUrl(scope.row.qrcd_url, type, scope.row.create_time);
    },
    formatPicO(scope, type) {
      return this.helper.picUrl(scope.row.imge_url, type, scope.row.create_time);
    },
    // 物料类型
    formatLeavType(row) {
      if (row.mtrl_type === 0) {
        return '原面料';
      }
      if (row.mtrl_type === 1) {
        return '辅料';
      }
      if (row.mtrl_type === 2) {
        return '包材';
      }
      if (row.mtrl_type === 3) {
        return '加工面料';
      }
    },
    // 二维码编号复制
    copySty(val) {
      const str = `mt${val}`;
      this.copy(str);
    },
    copy(data) {
      const url = data;
      const oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand('Copy'); // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      });
      oInput.remove();
    },
    // 二维码编号
    formaEr(row) {
      return `mt${row.qrcd_id}`;
    },
    formatLeavwidth(row) {
      return `${helper.haveFour(row.mtrl_width)}米`;
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    // 物料信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.qrcd_id);
          });
          post(qrcdAPI.deleteQrcdByIds, { qrcd_id_list: ids })
            .then(res => {
              const mg = res.data.msg;
              if (res.data.code === 0) {
                const tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage -= 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.getQrcdsList();
              } else {
                const tp = 'error';
                this.$message({ message: mg, type: tp });
                this.getQrcdsList();
              }
            })
            .catch(res => {
              const mg = res.data.msg;
              const tp = 'error';
              this.$message({ message: mg, type: tp });
              this.getQrcdsList();
            });
        })
        .catch(() => {
          const mg = '取消删除！';
          const tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    },
    // 查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}
::v-deep .el-checkbox__inner {
  border: 1px solid #080808;
}
.vd_dis {
  display: flex;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
</style>
